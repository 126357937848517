// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import VisualAnalytics from "../../blocks/visualanalytics/src/VisualAnalytics";
import FeedbackCollection from "../../blocks/FeedbackCollection/src/FeedbackCollection";
import SocialMediaAccountLoginScreen from "../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen";
import EmployeeLogin2 from "../../blocks/EmployeeLogin2/src/EmployeeLogin2";
import OrganisationHierarchy from "../../blocks/OrganisationHierarchy/src/OrganisationHierarchy";
import BreadcrumbNavigation from "../../blocks/BreadcrumbNavigation/src/BreadcrumbNavigation";
import TaxCalculator from "../../blocks/TaxCalculator/src/TaxCalculator";
import AdvancedSearch from "../../blocks/advancedsearch/src/AdvancedSearch";
import CentralisedBilling from "../../blocks/CentralisedBilling/src/CentralisedBilling";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import AdminConsole2 from "../../blocks/AdminConsole2/src/AdminConsole2";
import Payments from "../../blocks/Payments/src/Payments";
import InvoiceBilling from "../../blocks/InvoiceBilling/src/InvoiceBilling";
import BudgetingForecasting from "../../blocks/BudgetingForecasting/src/BudgetingForecasting";
import AcceptPrepayments2 from "../../blocks/AcceptPrepayments2/src/AcceptPrepayments2";
import AccountTransfer from "../../blocks/AccountTransfer/src/AccountTransfer";
import PeopleManagement2 from "../../blocks/PeopleManagement2/src/PeopleManagement2";
import PaymentAdmin2 from "../../blocks/PaymentAdmin2/src/PaymentAdmin2";
import Customform from "../../blocks/customform/src/Customform";
import JobListing2 from "../../blocks/JobListing2/src/JobListing2";
import ExpenseTracking from "../../blocks/ExpenseTracking/src/ExpenseTracking";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import CollectTransactionFees from "../../blocks/CollectTransactionFees/src/CollectTransactionFees";
import SpendAnalysis from "../../blocks/SpendAnalysis/src/SpendAnalysis";
import Referrals from "../../blocks/Referrals/src/Referrals";
import GoalManagement from "../../blocks/GoalManagement/src/GoalManagement";
import MultilevelApproval from "../../blocks/MultilevelApproval/src/MultilevelApproval";
import UserGroups2 from "../../blocks/UserGroups2/src/UserGroups2";
import GstIntegration2 from "../../blocks/GstIntegration2/src/GstIntegration2";
import Scheduling from "../../blocks/scheduling/src/Scheduling";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import ReviewAndApproval from "../../blocks/ReviewAndApproval/src/ReviewAndApproval";
import DataEncryption from "../../blocks/DataEncryption/src/DataEncryption";
import CvresumeCandidateManagement2 from "../../blocks/CvresumeCandidateManagement2/src/CvresumeCandidateManagement2";
import SocialMediaAccountRegistrationScreen from "../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen";
import AutomaticReminders from "../../blocks/AutomaticReminders/src/AutomaticReminders";
import TeamBuilder from "../../blocks/TeamBuilder/src/TeamBuilder";
import ApiIntegration from "../../blocks/apiintegration/src/ApiIntegration";
import PayrollIntegration2 from "../../blocks/PayrollIntegration2/src/PayrollIntegration2";
import StripeGatewayApiFrontend from "../../blocks/StripeGatewayApiFrontend/src/StripeGatewayApiFrontend";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import LeaveTracker from "../../blocks/LeaveTracker/src/LeaveTracker";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import PasswordProtectedPages from "../../blocks/PasswordProtectedPages/src/PasswordProtectedPages";
import PerformanceTracker from "../../blocks/PerformanceTracker/src/PerformanceTracker";
import TabletSupport64 from "../../blocks/TabletSupport64/src/TabletSupport64";
import SummaryCard from "../../blocks/SummaryCard/src/SummaryCard";



const routeMap = {
VisualAnalytics:{
 component:VisualAnalytics,
path:"/VisualAnalytics"},
FeedbackCollection:{
 component:FeedbackCollection,
path:"/FeedbackCollection"},
SocialMediaAccountLoginScreen:{
 component:SocialMediaAccountLoginScreen,
path:"/SocialMediaAccountLoginScreen"},
EmployeeLogin2:{
 component:EmployeeLogin2,
path:"/EmployeeLogin2"},
OrganisationHierarchy:{
 component:OrganisationHierarchy,
path:"/OrganisationHierarchy"},
BreadcrumbNavigation:{
 component:BreadcrumbNavigation,
path:"/BreadcrumbNavigation"},
TaxCalculator:{
 component:TaxCalculator,
path:"/TaxCalculator"},
AdvancedSearch:{
 component:AdvancedSearch,
path:"/AdvancedSearch"},
CentralisedBilling:{
 component:CentralisedBilling,
path:"/CentralisedBilling"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},
AdminConsole2:{
 component:AdminConsole2,
path:"/AdminConsole2"},
Payments:{
 component:Payments,
path:"/Payments"},
InvoiceBilling:{
 component:InvoiceBilling,
path:"/InvoiceBilling"},
BudgetingForecasting:{
 component:BudgetingForecasting,
path:"/BudgetingForecasting"},
AcceptPrepayments2:{
 component:AcceptPrepayments2,
path:"/AcceptPrepayments2"},
AccountTransfer:{
 component:AccountTransfer,
path:"/AccountTransfer"},
PeopleManagement2:{
 component:PeopleManagement2,
path:"/PeopleManagement2"},
PaymentAdmin2:{
 component:PaymentAdmin2,
path:"/PaymentAdmin2"},
Customform:{
 component:Customform,
path:"/Customform"},
JobListing2:{
 component:JobListing2,
path:"/JobListing2"},
ExpenseTracking:{
 component:ExpenseTracking,
path:"/ExpenseTracking"},
ForgotPassword:{
 component:ForgotPassword,
path:"/ForgotPassword"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},
CollectTransactionFees:{
 component:CollectTransactionFees,
path:"/CollectTransactionFees"},
SpendAnalysis:{
 component:SpendAnalysis,
path:"/SpendAnalysis"},
Referrals:{
 component:Referrals,
path:"/Referrals"},
GoalManagement:{
 component:GoalManagement,
path:"/GoalManagement"},
MultilevelApproval:{
 component:MultilevelApproval,
path:"/MultilevelApproval"},
UserGroups2:{
 component:UserGroups2,
path:"/UserGroups2"},
GstIntegration2:{
 component:GstIntegration2,
path:"/GstIntegration2"},
Scheduling:{
 component:Scheduling,
path:"/Scheduling"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
ReviewAndApproval:{
 component:ReviewAndApproval,
path:"/ReviewAndApproval"},
DataEncryption:{
 component:DataEncryption,
path:"/DataEncryption"},
CvresumeCandidateManagement2:{
 component:CvresumeCandidateManagement2,
path:"/CvresumeCandidateManagement2"},
SocialMediaAccountRegistrationScreen:{
 component:SocialMediaAccountRegistrationScreen,
path:"/SocialMediaAccountRegistrationScreen"},
AutomaticReminders:{
 component:AutomaticReminders,
path:"/AutomaticReminders"},
TeamBuilder:{
 component:TeamBuilder,
path:"/TeamBuilder"},
ApiIntegration:{
 component:ApiIntegration,
path:"/ApiIntegration"},
PayrollIntegration2:{
 component:PayrollIntegration2,
path:"/PayrollIntegration2"},
StripeGatewayApiFrontend:{
 component:StripeGatewayApiFrontend,
path:"/StripeGatewayApiFrontend"},
EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:"/EmailAccountRegistration"},
LeaveTracker:{
 component:LeaveTracker,
path:"/LeaveTracker"},
EmailAccountLoginBlock:{
 component:EmailAccountLoginBlock,
path:"/EmailAccountLoginBlock"},
PasswordProtectedPages:{
 component:PasswordProtectedPages,
path:"/PasswordProtectedPages"},
PerformanceTracker:{
 component:PerformanceTracker,
path:"/PerformanceTracker"},
TabletSupport64:{
 component:TabletSupport64,
path:"/TabletSupport64"},
SummaryCard:{
 component:SummaryCard,
path:"/SummaryCard"},

  Home: {
component:AdvancedSearch,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  }

};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {
   
  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    
    return (
      <View style={{ height: '100vh', width: '100vw' }}>
        <TopNav />
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
      </View>
    );
  }
}

export default App;
